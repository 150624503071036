import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { css } from 'linaria'
import Layout from '~/components/Common/Layout'
import { STYLE } from '~/utils/constant'
import { rem } from '~/utils/helper'

const loginStyle = css`
  max-width: var(--width-max-content);
  margin-top: calc(var(--height-header) + 72px);
  margin-right: auto;
  margin-left: auto;

  @media screen and (${STYLE.MEDIA.PC}) {
    margin-top: 80px;
  }

  & > .wrapper {
    padding-right: 24px;
    padding-left: 24px;
  }

  & > .wrapper > .title {
    ${STYLE.MIXIN.CONTENT_TITLE};
  }

  & > .wrapper > .list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 64px;
    row-gap: 48px;

    @media screen and (${STYLE.MEDIA.PC}) {
      flex-direction: row;
      column-gap: 64px;
    }
  }

  & > .wrapper > .list > .item {
    display: flex;
    flex-basis: 396px;
    flex-direction: column;
    padding: 40px 32px;
    border-radius: 16px;
    background: var(--color-background-bright);
  }

  & > .wrapper > .list > .item > .title {
    margin-top: 24px;
    font-size: ${rem(20)};
    font-weight: bold;
    text-align: center;
  }

  & > .wrapper > .list > .item > .body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
  }

  & > .wrapper > .list > .item > .body > .text {
    margin-top: 8px;
    font-size: ${rem(16)};
    letter-spacing: 0;
    text-align: center;
  }

  & > .wrapper > .list > .item > .body > .help {
    margin-top: 34px;
    font-size: ${rem(16)};
    text-align: center;
  }

  & > .wrapper > .list > .item > .body > .buttonwrapper {
    ${STYLE.MIXIN.BUTTON}
  }

  & > .wrapper > .list > .item > .imagewrapper {
    order: -1;
    overflow: hidden;
  }

  & > .wrapper > .list > .item > .imagewrapper .image {
    display: block;
    height: 128px;
  }
`

const breadcrumb = [
  {
    label: 'ログイン',
  },
]

const seo = {
  title: 'ログイン',
}

const LoginPage = () => {
  return (
    <Layout breadcrumb={breadcrumb} seo={seo}>
      <main className={loginStyle}>
        <div className="wrapper">
          <h1 className="title">ログイン</h1>

          <dl className="list">
            <div className="item">
              <dt className="title">企業担当者の方</dt>
              <dd className="body">
                <p className="text">ASHIATOの管理画面へのアクセスはこちら</p>
                <div className="buttonwrapper">
                  <a className="button" href="https://app.ashiatohr.com/company/login" target="_blank" rel="noreferrer">
                    管理画面へ
                  </a>
                </div>
              </dd>
              <dd className="imagewrapper">
                <StaticImage
                  className="image"
                  src="../images/login-company.png"
                  objectFit="contain"
                  objectPosition="center"
                  placeholder="none"
                  alt=""
                />
              </dd>
            </div>
            <div className="item">
              <dt className="title">応募者・推薦者の方</dt>
              <dd className="body">
                <div className="buttonwrapper">
                  <a className="button" href="https://mypage.en-ashiato.com/login" target="_blank" rel="noreferrer">
                    マイページへ
                  </a>
                </div>
              </dd>
              <dd className="imagewrapper">
                <StaticImage
                  className="image"
                  src="../images/login-candidate.png"
                  objectFit="contain"
                  objectPosition="center"
                  placeholder="none"
                  alt=""
                />
              </dd>
            </div>
          </dl>
        </div>
      </main>
    </Layout>
  )
}

export default LoginPage
